import React, {useState} from "react"
import { navigate } from "gatsby"
import { Auth } from "aws-amplify"
import { useLogin } from "../hooks/useLogin";
import { awsconfig } from "../utils/aws-config"
import NewPassword from "../components/NewPassword"
import {
  validateEmail,
  validatePhone,
  validatePassword,
} from "../utils/authHelperFuncs"
import SignIn from "../components/SignIn"
import Layout2 from "../layouts/layout2";
import OverlayLoading from "../components/OverlayLoading";

Auth.configure(awsconfig)
const SignInPage = (props) => {

  const [isLoggedin, loggedInUser] = useLogin(Auth);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [countryCode, setCountryCode] = useState("+91")
  const [user, setUser] = useState("")
  const [firstName, setFirstName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordAgain, setNewPasswordAgain] = useState("")
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false)
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false)
  const [showCountryCode, setShowCountryCode] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [signinErrorMessage, setSigninErrorMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [isSigninError, setIsSigninError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState("")

  const loadingCallback = (isLoading, msg) => {
    setLoading(isLoading)
    setLoadingMsg(msg)
  }
  const onUserNameChange = (e) => {
    setUsername(e.target.value);
    if (isNaN(e.target.value) || e.target.value === "") {
      setShowCountryCode(false);
    } else {
      setShowCountryCode(true);
    }
    if (e.target.value === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value)
      if (!validatePassword(e.target.value)) {
        setIsPasswordError(true)
      } else {
        setIsPasswordError(false)
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value)
      if (newPassword !== e.target.value) {
        setIsRepeatPasswordError(true)
      } else {
        setIsRepeatPasswordError(false)
      }
    }
  }
  const onNameChange = (e) => {
    if (e.target.name === "firstName") setFirstName(e.target.value)
    if (e.target.name === "familyName") setFamilyName(e.target.value)
  }
  const signIn = async (e) => {
    e.preventDefault()
    
    if (username === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
    } else {
      setIsError(false);
      setErrorMessage("");
    }

    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`
      loadingCallback(true, "signing in...")
      const user = await Auth.signIn(userName, password)
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user)
        setIsNewPasswordRequired(true)
        loadingCallback(false, "")
      } else {
        let productId = sessionStorage.getItem("productId")
        let type = sessionStorage.getItem("type")
        let freq = sessionStorage.getItem("freq")
        if(!!productId) {
          window.location.href = `/usercheckoutdetail/?pid=${productId}&type=${type}&freq=${freq}`
        } else {
          navigate("/")
        }
      }
    } catch (err) {
      setIsSigninError(true)
      setSigninErrorMessage(err.message)
      loadingCallback(false, "")
    }
  }
  const onNewPasswordSubmit = async (e) => {
    e.preventDefault()
    try {
      loadingCallback(true, "Setting new password...")
      const response = await Auth.completeNewPassword(user, newPassword, {
        given_name: firstName,
        family_name: familyName,
      })
      loadingCallback(false, "")
      navigate("/")
    } catch (err) {
      setIsSigninError(true)
      setSigninErrorMessage(err.message)
      loadingCallback(false, "")
    }
  }

  return (
    <Layout2>
      {
        loading ? <OverlayLoading overlayText={loadingMsg}/> : 
        <>
        <div className="courses-wrapper">
          <div className="design-wrapper">
            {isNewPasswordRequired ? (
              <NewPassword
                firstName={firstName}
                familyName={familyName}
                newPassword={newPassword}
                newPasswordAgain={newPasswordAgain}
                isPasswordError={isPasswordError}
                isRepeatPasswordError={isRepeatPasswordError}
                onNameChange={onNameChange}
                onPasswordChange={onPasswordChange}
                onNewPasswordSubmit={onNewPasswordSubmit}
                showPassword={showNewPassword}
                toggleShowPassword={() => setShowNewPassword(!showNewPassword)}
              />
            ) : (
              <SignIn
                username={username}
                password={password}
                errorMessage={errorMessage}
                signinErrorMessage={signinErrorMessage}
                isSigninError={isSigninError}
                showCountryCode={showCountryCode}
                isError={isError}
                onUserNameChange={onUserNameChange}
                onPasswordChange={(e) => setPassword(e.target.value)}
                showPassword={showPassword}
                toggleShowPassword={() => setShowPassword(!showPassword)}
                codeChange={(country_code) => setCountryCode(country_code)}
                signIn={signIn}
              />
            )}
          </div>
        </div>
      </>}
    </Layout2>
  )
}
export default SignInPage