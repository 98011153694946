import React from "react"
import "./FormPassword.css"

const FormPassword = (props) => {

  return (
    <div className="username-input">
      <label>
        {props.label}<span className="asterisk">*</span>
        <div className="div-input">
          <input
            type={props.showPassword ? "text" : "password"}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.inputOnChange}
            required
            name={props.name}
            className={`${props.isInValid ? "outline-red" : ""}`}
          />
        </div>
      </label>
      {props.isInValid && <span className="error-msg mt-10">
        {props.errorMessage}
      </span>}
    </div>
  )
}
export default (FormPassword)
