import React, {useState} from "react"
import ErrorMessageForPassword from "../ErrorMessageForPassword"
import FormInput from "../FormInput"
import FormPassword from "../FormPassword"
import "./NewPassword.css"

const NewPassword = (props) => {

  const [userNewPassword, setUserNewPassword] = useState(props.newPassword)
  const [userRepeatPassword, setUserRepeatPassword] = useState(props.newPasswordAgain)

    return (
      <div className="new-psw-wrap">
        <div className="new-psw-container">
          <h2>
            Enter new password
          </h2>
          <form onSubmit={props.onNewPasswordSubmit}>
            <FormInput
              label="Enter First Name"
              name="firstName"
              type="text"
              placeholder="Enter First Name"
              inputOnChange={props.onNameChange}
              value={props.firstName}
            />
            <FormInput
              label="Enter Family Name"
              name="familyName"
              type="text"
              placeholder="Enter Family Name"
              inputOnChange={props.onNameChange}
              value={props.familyName}
            />
            <FormPassword
              label="New Password"
              name="new_password"
              showPassword={props.showPassword}
              toggleShowPassword={props.toggleShowPassword}
              placeholder=""
              inputOnChange={(e) => {
                props.onPasswordChange(e)
                setUserNewPassword(e.target.value)
              }}
              value={props.userNewPassword}
              errorMessage={<ErrorMessageForPassword newPassword={userNewPassword} />}
              isInValid={props.isPasswordError}
            />
            <FormPassword
              label="Enter New Password Again"
              name="new_password_again"
              showPassword={props.showPassword}
              toggleShowPassword={props.toggleShowPassword}
              placeholder=""
              inputOnChange={(e) => {
                props.onPasswordChange(e)
                setUserRepeatPassword(e.target.value)
              }}
              value={props.userRepeatPassword}
              errorMessage={"Your password should match with the above one."}
              isInValid={props.isRepeatPasswordError}
            />
            <div spacing={6}>
              <button
                type="submit"
                className="signin-button"
              >
                Create Password
              </button>
            </div>
          </form>
        </div>
      </div>
    )
}
export default (NewPassword)
