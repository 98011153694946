import React, {useState} from "react"
import {Link} from "gatsby"
import CountryCodeSelect from "../CountryCodeSelect"
import FormPassword from "../FormPassword"
import "./SignIn.css"

const SignIn = (props) => {

  const[userPassword, setUserPassword] = useState(props.password)

    return (
      <div className="signin-wrap">
        <div>
          <div className="heading">
            <h2>Sign in to your account</h2>
          </div>
          <div className="form-wrap">
            <form onSubmit={props.signIn}>
              <div>
                {props.isSigninError && (
                  <p className="error-msg">
                    {props.signinErrorMessage}
                  </p>
                )}
                <label className="username-input">
                Email or Phone number or Username<span className="asterisk">*</span>
                  <div className="div-input flex">
                  {props.showCountryCode && (
                      <CountryCodeSelect codeChange={props.codeChange} />
                    )}
                    <input
                        id="email-phone"
                        type="text"
                        name="username"
                        value={props.username}
                        onChange={props.onUserNameChange}
                        placeholder="Email or Phone number or Username"
                        label="Email or Phone number or Username"
                        className={`${props.errorMessage ? "outline-red" : ""}`}
                    />
                  </div>
                </label>
                <span className="error-msg">
                  {props.errorMessage}
                </span>
              </div>
              <FormPassword
                label="Password"
                name="password"
                showPassword={props.showPassword}
                toggleShowPassword={props.toggleShowPassword}
                placeholder="password"
                inputOnChange={(e) => {
                  props.onPasswordChange(e)
                  setUserPassword(e.target.value)
                }}
                value={userPassword}
              />
              <div>
                <button
                  className="signin-button"
                  type="submit"
                >
                  Sign in
                </button>
                <div className="forgot-psw-button">
                  <Link to={`/forgotpassword`}>
                    Forgot password?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
}

export default (SignIn)
