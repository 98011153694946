
import React from "react"
import "./ErrorMessageForPassword.css"

const ErrorMessageForPassword = (props) => {

  return(
    <>
      <p className={`${/[a-z]/.test(props.newPassword) ? "pass" : "fail"}`}>
        Password must contain a lower case letter.
      </p>
      <p className={`${/[A-Z]/.test(props.newPassword) ? "pass" : "fail"}`}>
        Password must contain an upper case letter.
      </p>
      <p className={`${/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(props.newPassword) ? "pass" : "fail"}`}>
        Password must contain a special character.
      </p>
      <p className={`${/\d/.test(props.newPassword) ? "pass" : "fail"}`}>
        Password must contain a number.
      </p>
      <p className={`${props.newPassword.length >= 8 ? "pass" : "fail"}`}>
        Password must contain at least 8 characters.
      </p>
    </>
  )
}
export default (ErrorMessageForPassword)
