import React from "react"
import "./FormInput.css"

const FormInput = (props) => {

  return (
    <div className="username-input">
      <label>
        {props.label}<span className="asterisk">*</span>
        <div className="div-input">
          <input
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.inputOnChange}
            name={props.name}
            readOnly={props.readOnly}
            required
            className={`${props.isInValid ? "outline-red" : ""}`}
          />
        </div>
      </label>
      <span display="block">{props.errorMessage}</span>
    </div>
  )
}
export default (FormInput)
