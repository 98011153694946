import React from 'react'
import './OverlayLoading.scss'

const OverlayLoading = (props) => {
    return (
        <div className="overlay loading">
            <div className="flex">
                <div className='page-loader'>
                </div>
                <div className="overlay-text">{props.overlayText}</div>
            </div>
        </div>
    )
}

export default OverlayLoading