import React from "react"
// import { country_codes } from "../../utils/countryCodes"
import "./CountryCodeSelect.css"

class CountryCodeSelect extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      value: ""
    }
  }

  onChange = (e) => {
    this.setState({value: e.target.value})
    this.props.codeChange(e.target.value.split(" ")[0])
  }
  render() {
    const country_codes = [
      {
        name: "India",
        dial_code: "+91",
        code: "IN"
        // flag: require("../countryFlags/india.png"),
      },
      {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD"
        // flag: require("../countryFlags/bangladesh.png"),
      },
      {
        name: "Sri Lanka",
        dial_code: "+94",
        code: "LK"
        // flag: require("../countryFlags/sri-lanka.png"),
      },
      {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE"
        // flag: require("../countryFlags/united-arab-emirates.png"),
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US"
        // flag: require("../countryFlags/usa.png"),
      },
    ];

    return (
      <div className="select-country-code">
        <select value={this.state.value} onChange={this.onChange} onBlur={this.onChange} name="Country code">
          {country_codes.map((country) => (
            <option key={country.dial_code} value={country.dial_code}>
              {country.dial_code} {country.code}
            </option>
          ))}
        </select>
        <div className="select__icon-wrapper">
          <svg
            viewBox="0 0 24 24"
            role="presentation"
            className="select__icon"
            focusable="false"
            aria-hidden="true"
          >
            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        </div>
      </div>
    )
  }
}
export default (CountryCodeSelect)
